@import "minima";

ul.blog-nav{
  align-items:center;
  border-bottom: 1px solid #e8e8e8;
  display:flex;
  justify-content: center;
  list-style-type: none;
  margin: 0 0 20px 0;
  padding: 0;
  width: 100%;
  & > li {
    font-size: 24px;
    margin-bottom: 20px;
    text-align:center;
    width: 50%;
  }
  & > li.active {
    font-weight: 600;
  }
}

.category-head {
  font-size: 24px;
}

.category-item {
  font-size: 20px;
}

.comments {
  border-top: 1px solid #e8e8e8;
  padding-top: 30px;
}

.greater-icon {
  height: 100%;
  width: 1.05rem;
}

.greater-icon path {
  stroke: currentColor;
  stroke-width: 8px;
}

.footer-col {
  margin-bottom: unset;
}

.post-back {
  display: block;
  font-size: 24px;
  margin-bottom: 10px;
  width: fit-content;
  > span {
    font-size: 22px;
  }
}

.post-link {
  margin-bottom: -15px;
  &:hover {
    background: #f5f5f5;
  }
}

.site-footer {
  padding: 25px 0;
}

.site-title {
  font-size: 24px;
  font-weight: 600;
}

@keyframes cursor{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.site-title-cursor {
  -webkit-animation: cursor 1s infinite;
  animation: cursor 1s infinite;
  background: #fe5186;
  border-radius: 1px;
  display: inline-block;
  height: 1.2rem;
  margin-bottom: -2px;
  margin-left: 5px;
  width: 10px;
}

ul.social-media-list{
  margin-left: -5px;
  > li {
    display: inline-block;
    > a {
      border: 1px solid #e8e8e8;
      margin: 0 5px;
      padding: 5px 6px;
      text-align: center;
    }
  }
}

.svg-icon {
  padding-right: unset;
}
